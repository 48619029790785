import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { ReactComponent as ArrowUp } from '../../images/icons/arrowUp.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/chevron_right.svg';
import { ReactComponent as ClockIcon } from '../../images/icons/time.svg';
import { ReactComponent as TrashIcon } from '../../images/icons/delete.svg';

import { ReactComponent as PuggySearch } from '../../images/icons/puggy_search.svg';
import { ReactComponent as PuggyShopping } from '../../images/puggy_shopping.svg';
import styles from './Suggestions.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import Product from '../Product/Product';
import getCDNImage from '../../utils/getCDNImage';
import { useSwitchableFeatures } from '../../features/SwitchableFeature/SwitchableFeature';
import { CART, IMAGE_SEARCH_FEATURE } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import { useImageSearch } from '../../utils/useImageSearch';
import { setTriggerChat } from '../../state/chat/chat';
import Button, { SECONDARY } from '../Button/Button';
import { useSearchHistory } from '../../utils/useSearchHistory';
import ImageUploadMobileWidget from '../ImageUploadMobileWidget/ImageUploadMobileWidget';

const Highlight = ({ text, query }) => {
  let fullText = text;
  if (!query?.length) return text;
  const parts = [];
  //embolden suggested text exept user query
  while (fullText) {
    const pos = fullText.toLowerCase().indexOf(query.toLowerCase());
    if (pos === -1 && fullText.length) {
      //no more occurances left
      parts.push(<b key={Math.random()}>{fullText}</b>);
      break;
    }
    if (pos > 0) {
      parts.push(<b key={Math.random()}>{fullText.substr(0, pos)}</b>);
    }
    parts.push(fullText.substr(pos, query.length));
    fullText = fullText.substr(pos + query.length);
  }

  return parts;
};

const getSuggestionsObject = (suggestions, typeOrder, searchHistory, query) => {
  const suggestionObject = {};
  const lowerCasedQuery = query?.toLowerCase();
  const filteredHistory = !searchHistory?.length
    ? []
    : searchHistory
        .filter((item) => item.toLowerCase().includes(lowerCasedQuery))
        .slice(0, 5)
        .map((text, index) => ({ text, index: index + suggestions.length }));

  if (filteredHistory.length) suggestionObject.history = filteredHistory;

  if (!suggestions?.length && !filteredHistory.length) return {};
  if (!suggestions?.length) return suggestionObject;

  suggestions.sort((a, b) => typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type));
  suggestions
    .filter((item) => item.type !== 'shop')
    .forEach((item, index) => {
      const itemType = item.type;

      if (!suggestionObject[itemType]) {
        suggestionObject[itemType] = [];
      }
      suggestionObject[itemType] = [...suggestionObject[itemType], { ...item, index }];
    });
  return suggestionObject;
};

const Suggestions = React.memo(
  ({
    onClose,
    onSelect,
    pickHistoryItem,
    query,
    selected,
    suggestions = [],
    productSugestions,
    isOpen,
    proceedSearch,
  }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { onImageSearchFileChange, errorMessage, setErrorMessage } = useImageSearch();
    const suggestionRef = useRef(null);
    const searchProducts = productSugestions;
    const products = (searchProducts?.hits || [])
      .slice(0, 6)
      .map((hit) => ({ ...hit._source, id: hit._id }))
      .filter((product) => product);
    const totalProducts = searchProducts?.total?.value || 0;
    const typeOrder = ['product', 'category', 'brand'];
    const { searchHistory, removeHistoryItem } = useSearchHistory();
    const suggestionObject = getSuggestionsObject(suggestions, typeOrder, searchHistory, query);
    const [marketplaceVersion, imageSearchFeature] = useSwitchableFeatures([CART, IMAGE_SEARCH_FEATURE]);

    const handleShowAll = () => {
      if (typeof proceedSearch === 'function') proceedSearch();
      handleClose();
    };

    const handleClose = () => {
      onClose();

      if (errorMessage) {
        setErrorMessage('');
      }
    };

    const handleOpenChat = () => {
      dispatch(setTriggerChat(true));
      handleClose();
    };

    const getSuggestionName = (type) => {
      switch (type) {
        case 'category':
          return intl.formatMessage({ id: 'search.suggestionsTags.cat' });
        case 'brand':
          return intl.formatMessage({ id: 'search.suggestionsTags.brand' });
        case 'product':
          return intl.formatMessage({ id: 'search.suggestionsTags.suggestion' });
        case 'history':
          return intl.formatMessage({ id: 'search.suggestionsTags.history' });
        default:
          return '';
      }
    };

    const removeSearchHistoryItem = (query) => (e) => {
      removeHistoryItem(query);
    };

    useEffect(() => {
      const handleClickOutside = (e) => {
        if (suggestionRef.current && !suggestionRef.current.contains(e.target) && onClose) {
          handleClose();
        }
      };

      window.addEventListener('mousedown', handleClickOutside);

      return () => {
        window.removeEventListener('mousedown', handleClickOutside);
      };
    }, [suggestionRef, onClose]);

    useEffect(() => {
      if (!isOpen) {
        return;
      }
      const body = document.body;
      if (!body) {
        return;
      }

      const handleScroll = (event) => {
        event.preventDefault();
      };

      body.classList.add('no-scroll');
      body.addEventListener('scroll', handleScroll);

      return () => {
        body.classList.remove('no-scroll');
        body.removeEventListener('scroll', handleScroll);
      };
    }, [isOpen]);

    if (!suggestions?.length && !marketplaceVersion) return null;
    const emptyWrapper = !marketplaceVersion && !suggestions?.length;
    return (
      <>
        <div
          className={classNames(styles.backgroundLayer, {
            [styles.visible]: marketplaceVersion && isOpen && !emptyWrapper,
          })}
        />
        <div
          className={classNames({
            [styles.suggestionsWrapper]: !marketplaceVersion,
            [styles.suggestionsWrapperMP]: marketplaceVersion,
            [styles.visible]: isOpen,
            [styles.suggestionsWrapperEmpty]: emptyWrapper,
          })}
          ref={suggestionRef}
        >
          <div
            className={classNames({
              [styles.suggestionBox]: !marketplaceVersion,
              [styles.suggestionBoxMP]: marketplaceVersion && products?.length,
              [styles.suggestionBoxMPNoProducts]: marketplaceVersion && !products?.length,
            })}
            data-testid="suggestionBox"
            tabIndex="0"
          >
            {imageSearchFeature && errorMessage && <div className={styles.errorArea}>{errorMessage}</div>}
            {(marketplaceVersion || imageSearchFeature) && (
              <div className={classNames(styles.searchIconAction, { [styles.searchIconActionError]: !!errorMessage })}>
                {imageSearchFeature && (
                  <div className={styles.actionWrapper}>
                    <ImageUploadMobileWidget
                      closePopup={handleClose}
                      onImageSearchFileChange={onImageSearchFileChange}
                      className={styles.imageSearch}
                    />
                  </div>
                )}
                {marketplaceVersion && (
                  <div className={styles.actionWrapper} onClick={handleOpenChat}>
                    <PuggySearch />
                    <FormattedMessage id="search.chatWithPuggy" defaultMessage="Chat with puggy" />
                  </div>
                )}
              </div>
            )}
            <div
              className={classNames({
                [styles.suggestionCategory]: !marketplaceVersion,
                [styles.suggestionCategoryMP]: marketplaceVersion,
                [styles.suggestionCategoryMPNoProducts]: marketplaceVersion && !products?.length,
              })}
            >
              {Object.keys(suggestionObject).map((suggestionType) => (
                <div
                  key={suggestionType}
                  className={classNames({
                    [styles.categoryArea]: suggestionType === 'category',
                    [styles.suggestionArea]: suggestionType === 'product',
                    [styles.brandArea]: suggestionType === 'brand',
                  })}
                >
                  <div className={styles.suggestionName}>{getSuggestionName(suggestionType)}</div>
                  {!!suggestionObject[suggestionType]?.length && (
                    <div
                      className={
                        suggestionType === 'brand' ? styles.suggestionBrandTypeWrapper : styles.suggestionTypeWrapper
                      }
                    >
                      {suggestionObject[suggestionType]?.map((suggestion, index) => (
                        <div
                          className={classNames(styles.suggestionType, {
                            [styles.selected]: selected === suggestion.index,
                          })}
                          key={suggestion + index}
                          data-testid={`suggestion${index}`}
                        >
                          <button
                            className={styles.link}
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={() => {
                              if (suggestionType === 'history' && pickHistoryItem) {
                                pickHistoryItem(suggestion.text);
                                return;
                              }
                              if (onSelect) {
                                onSelect(suggestion.index);
                              }
                            }}
                          >
                            {suggestionType === 'history' && <ClockIcon className={styles.clockIcon} />}
                            <span className={styles.query}>
                              <Highlight text={suggestion.text} query={query} />
                            </span>
                            {suggestion.catRoute && suggestion.catRoute.length > 1 && (
                              <>
                                <span className={styles.in}> in {suggestion.catRoute[0]}</span>
                              </>
                            )}
                          </button>
                          {suggestionType === 'history' && (
                            <TrashIcon
                              onClick={removeSearchHistoryItem(suggestion.text)}
                              className={styles.removeHistoryItem}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {marketplaceVersion && !!products?.length && (
              <div className={styles.suggestionProducts}>
                {products.map((product) => (
                  <Product
                    adult={product?.adult}
                    classList={{ root: styles.productCard }}
                    shopRating={product.shopRating?.rating}
                    key={product.id}
                    brand={product?.brand}
                    currency={product?.currency || process.env.REACT_APP_WEBSITE_CURRENCY}
                    shortDesc={product?.shortDesc}
                    EAN={product?.offers?.[0]?.EAN}
                    price={product.price}
                    originalPrice={product.price_original || product.price}
                    id={product.id}
                    image={getCDNImage(product?.thumbs?.[0]?.url)}
                    name={product.name}
                    shop={{ name: product.campaign }}
                    SKU={product.SKU}
                    showProductLink={false}
                    url={product.canonical_path}
                    status={product.new}
                    shopId={product?.shop_id}
                    merchantPid={product?.merchant_product_id}
                    // view={VIEW_OPTIONS_COLUMNS}
                  />
                ))}
                <div className={styles.actions}>
                  <Button onClick={handleClose} inverted variation={SECONDARY} className={styles.closeSuggestionsBtn}>
                    <ArrowUp /> &nbsp; <FormattedMessage id="common.close" defaultMessage="close" />
                  </Button>
                  <Button onClick={handleShowAll} variation={SECONDARY} className={styles.showAllBtn}>
                    <span>
                      <FormattedMessage
                        id="search.showAllResult"
                        values={{ totalResult: totalProducts }}
                        defaultMessage="Show all {totalResult} results"
                      />
                    </span>

                    <ArrowRight fill="#3293E6" />
                  </Button>
                </div>
              </div>
            )}
            {marketplaceVersion && !products?.length && (
              <div className={styles.noProducts}>
                <div className={styles.shoppingPuggy}>
                  <PuggyShopping />
                  <p>
                    <FormattedMessage id="search.noSuggestions" />
                  </p>
                </div>
                <div className={styles.actions}>
                  <Button onClick={handleClose} inverted variation={SECONDARY} className={styles.closeSuggestionsBtn}>
                    <ArrowUp /> &nbsp; <FormattedMessage id="common.close" defaultMessage="close" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

Suggestions.displayName = 'Sugestions';

export default Suggestions;
